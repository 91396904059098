import Vue from 'vue';
import Router from 'vue-router'

Vue.use(Router);

const routerOptions = [
  {
    path: '/',
    name: 'public',
    visible: false
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   visible: false,
  //   meta: {
  //     title: 'Dashboard'
  //   }
  // },
  {
    path: '/home',
    name: 'home',
    visible: false,
    meta: {
      title: 'Inicio'
    }
  },
  {
    path: '/login',
    name: 'login',
    visible: false,
  },
  {
    path: '/register',
    name: 'register',
    visible: false,
  },
  {
    path: '/logout',
    name: 'logout',
    visible: false,
  },
  {
    path: "/production/mes/dashboard",
    name: "production/mes/dashboard",
    meta: {
      title: "MES | Dashboard",
      permissions: [
         "view-production"
      ],
    },
    visible: true,
  },
  {
    path: "/production/mes/pareto",
    name: "production/mes/pareto",
    meta: {
      title: "Pareto de demoras",
      permissions: [
        "view-production"
      ],
    },
    visible: true,
  },
  {
    path: "/finance/dashboard",
    name: "finance/dashboard",
    meta: {
      title: "Finanzas | Dashboard",
      permissions: [
       "view-finance"
      ],
    },
    visible: true,
  },
  {
    path: "/users",
    name: "users",
    meta: {
        title: "Usuarios / Gestión de Usuarios",
        permissions: [
            "get-users",
            "store-users",
            "update-users",
            "delete-users",
            "get-abilities",
            "store-abilities",
            "update-abilities",
            "delete-abilities",
            "get-roles",
            "store-roles",
            "update-abilities",
            "delete-abilities",
        ],
    },
    visible: true,
},
]

// Rutas
const routes = routerOptions.map(r => {
  return {
    ...r,
    component: () => import(`../components/${r.name}/index-component.vue`)
  }
})

const router = new Router({
  // mode: 'history',
  base: __dirname,
  routes
})

export default router