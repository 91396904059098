<template>
  <div>
    <v-app-bar app clipped-left fixed>
      <v-row align="center">
        <v-col cols="1" sm="1" md="1" lg="1" xl="1">
          <v-btn block outlined @click.stop="drawerConf = !drawerConf">
            <span class="mdi mdi-menu"></span>
          </v-btn>
        </v-col>
        <v-col cols="9" sm="9" md="9" lg="9" xl="9">
          <span class="subtitle-2 mx-auto font-weight-bold text-truncate">{{ $route.meta.title }} </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" sm="2" md="2" lg="2" xl="2">
          <quick-access-component></quick-access-component>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer v-model="drawerConf" temporary fixed width="20%">
      <template v-slot:prepend>
        <v-list-item class="px-4 pt-1">
          <v-list-item-title>Menú</v-list-item-title>
          <v-btn icon @click.stop="drawerConf = !drawerConf">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item>
      </template>
      <v-divider></v-divider>

      <v-list dense nav rounded>
        <!-- <v-list-item-group no-action color="accent" @click="changeModule(true)" :value="banner">
          <v-list-item @click="goTo('dashboard')">
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group> -->
        <v-list-group v-for="module in modulesOrder" :key="module.title" v-show="can(module.permissions)"
          v-model="module.active" :prepend-icon="module.action" no-action color="accent"
          @click="changeModule(module.banner)">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ module.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list v-if="module.subsections">
            <v-list-group v-for="sections in module.sections" :key="sections.title" no-action class="pl-15"
              color="accent" :value="banner">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-show="can(sections.permissions)">{{ sections.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="subsections in sections.sections" :key="subsections.title" link class="pl-10"
                @click="goTo(subsections.redirect)">
                <v-list-item-title v-show="can(subsections.permissions)">{{ subsections.title }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>

          <v-list-item v-else v-for="section in module.sections" :key="section.title" link
            v-show="can(section.permissions)" @click="goTo(section.redirect)">
            <v-list-item-content>
              <v-list-item-title v-show="can(section.permissions)">{{ section.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <v-subheader class="mt-2">Configuración</v-subheader>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-brightness-6</v-icon>
            </v-list-item-icon>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch color="accent" v-model="get_is_dark" v-on:change="setDarkMode(get_is_dark)"></v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item link @click="goTo('home')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ getConfigurationName }} (Inicio)
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon color="error">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :style="{ color: styleErrorColor }">
              Cerrar Sesión
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import default_profile_picture from "../users/images/default_profile_picture.png";
import { can } from '../../src/helpers/permissions';
import { mapState, mapActions, mapGetters } from "vuex";
import quickAccessComponent from "./quick-access-component.vue";
import _ from 'lodash';
export default {
  data: () => ({
    banner: true,
    drawer: true,
    group: null,
    drawerConf: false,
    modules: [
      {
        title: 'Usuarios',
        action: 'mdi-account-group',
        active: false,
        permissions: [
          'get-users', 'store-users', 'update-users', 'delete-users',
          'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
          'get-roles', 'store-roles', 'update-abilities', 'delete-abilities',
        ],
        subsections: false,
        sections: [
          {
            title: 'Gestión de Usuarios',
            redirect: 'users',
            permissions: [
              'get-users', 'store-users', 'update-users', 'delete-users',
              'get-abilities', 'store-abilities', 'update-abilities', 'delete-abilities',
              'get-roles', 'store-roles', 'update-abilities', 'delete-abilities',
            ],
          },
        ],
        banner: false,
      },
      {
        title: 'Finanzas',
        action: 'mdi-finance',
        // redirect: 'equipment',
        permissions: [
          'view-finance'
        ],
        subsections: false,
        sections: [
          {
            title: 'Dashboard',
            redirect: 'finance/dashboard',
            permissions: [
              "view-finance"
            ],
          }
        ],
        banner: false,
      },
      {
        title: 'Produccion',
        action: 'mdi-cogs',
        // redirect: 'equipment',
        permissions: [
          "view-production"
        ],
        subsections: false,
        sections: [
          {
            title: 'Disponibilidad',
            redirect: 'production/mes/dashboard',
            permissions: [
              "view-production"
            ],
          },
          {
            title: 'Análisis de demoras',
            redirect: 'production/mes/pareto',
            permissions: [
              "view-production"
            ],
          }
        ],
        banner: false,
      },
      {
        title: 'Proyectos',
        action: 'mdi-view-dashboard',
        // redirect: 'equipment',
        permissions: [
          "view-projects"
        ],
        subsections: false,
        sections: [
          {
            title: 'Proeco',
            redirect: 'project/proeco',
            permissions: [
              "view-projects"
            ],
          },
          {
            title: 'Resumen',
            redirect: 'project/resumen',
            permissions: [
              "view-projects"
            ],
          },
        ],
        banner: false,
      }

    ],
  }),
  components: {
    quickAccessComponent
  },
  computed: {
    ...mapState('configuration', ['themeDark']),
    ...mapState("authentication", ["userAuth"]),
    ...mapGetters('configuration', ['getAbilitiesUser', 'getConfigurationName', 'getPlantsAssign']),
    modulesOrder() {
      return _.orderBy(this.modules, 'title', 'asc');
    },
    logged() {
      return this.$route.name !== "home";
    },
    sideMenu() {
      return this.$vuetify.breakpoint.width <= 1264 && this.logged;
    },
    get_is_dark: {
      get: function () {
        return this.themeDark;
      },
      set: function (newValue) {
        return newValue;
      },
    },
    styleErrorColor() {
      return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.error : this.$vuetify.theme.themes.light.error;
    }
  },
  methods: {
    ...mapActions("configuration", ["setDarkMode"]),
    can(permissions) {
      return can(this.getAbilitiesUser, permissions);
    },
    logout() {
      this.$router.push({ name: "logout" });
    },
    goTo(name) {
      this.banner = false
      this.$router.push({ name: name }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    showhere(route) {
      return this.$route.fullPath != route ? true : false;
    },
    checkImage(image) {
      if (
        image == "default_user_image.jpg" ||
        image == "default_profile_picture.png" ||
        image == null
      ) {
        return default_profile_picture;
      } else {
        return image;
      }
    },
    changeModule(banner) {
      this.banner = banner
    }

  },
  watch: {
    get_is_dark: function (val) {
      this.$vuetify.theme.dark = val;
    },
  },
  mounted() {
    this.$vuetify.theme.dark = this.get_is_dark;
  },
};
</script>

<style>
.router-link-exact-active {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #1867c0 !important;
  caret-color: #1867c0 !important;
  font-style: italic;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

}
</style>