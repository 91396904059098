import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);
// -----------------------  CONFIGURATION MODULES ------------------------
import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import abilities            from  './modules/abilities.js';
import roles                from  './modules/roles.js';
import files                from  './modules/files.js';
import maps                 from  './modules/maps.js';
import user                 from  './modules/user.js';
import cid                  from  './modules/cid.js';
import attachments          from  './modules/attachments.js';
import activities           from  './modules/activities.js';

const dataState = createPersistedState({
  paths: ['configuration']
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    roles,
    files,
    maps,
    user,
    cid,
    attachments,
    activities
  },
  plugins: [dataState]
})